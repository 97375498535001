@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Red+Hat+Mono:ital,wght@0,300..700;1,300..700&family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");

/* index.css */
body {
  cursor: default !important;
}

body,
[data-rbd-drag-handle-draggable-id] {
  cursor: default !important;
}

.body {
  margin: 0;
  font-family: "Red Hat Display", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
  font-family: "Red Hat Display", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.header {
  font-family: "Red Hat Display", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-size: 68px;
}
